// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-hands-on-workshop-timextender-js": () => import("./../src/pages/hands-on-workshop-timextender.js" /* webpackChunkName: "component---src-pages-hands-on-workshop-timextender-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-klanten-js": () => import("./../src/pages/klanten.js" /* webpackChunkName: "component---src-pages-klanten-js" */),
  "component---src-pages-nieuws-js": () => import("./../src/pages/nieuws.js" /* webpackChunkName: "component---src-pages-nieuws-js" */),
  "component---src-pages-oplossingen-js": () => import("./../src/pages/oplossingen.js" /* webpackChunkName: "component---src-pages-oplossingen-js" */),
  "component---src-pages-over-ddcgroup-js": () => import("./../src/pages/over-ddcgroup.js" /* webpackChunkName: "component---src-pages-over-ddcgroup-js" */),
  "component---src-pages-timextender-js": () => import("./../src/pages/timextender.js" /* webpackChunkName: "component---src-pages-timextender-js" */),
  "component---src-pages-vacatures-js": () => import("./../src/pages/vacatures.js" /* webpackChunkName: "component---src-pages-vacatures-js" */),
  "component---src-templates-blog-list-js": () => import("./../src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-cases-list-js": () => import("./../src/templates/cases-list.js" /* webpackChunkName: "component---src-templates-cases-list-js" */),
  "component---src-templates-detail-page-js": () => import("./../src/templates/detail-page.js" /* webpackChunkName: "component---src-templates-detail-page-js" */)
}

